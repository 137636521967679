/* Navbar Styles */
.navbar {
    display: flex;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #333;
    padding: 10px;
    z-index: 1000;
    color: #fff;
}

.menu {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #333;
    border: 2px solid #333;
    margin-right: 10px;
}

.menu img {
    width: 1200%;
    height: 1200%;
    object-fit: contain;
}

.logout {
    background-color: #6c757d;
    margin-right: 20px;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;
    font-weight: bold;
}

.logout:hover {
    background-color: #5a6268;
}

/* Button Styles */
button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
}

button.primary {
    background-color: #007bff;
    color: #fff;
}

button.primary:hover {
    background-color: #0056b3;
}

button.secondary {
    background-color: #6c757d;
    color: #fff;
}

button.secondary:hover {
    background-color: #5a6268;
}

/* Dashboard Sections Styles */
.Admin,
.Student,
.Parent,
.Tutor {
    margin-top: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 20px;
    background-color: #fff;
    color: #333;
}

/* Sidebar Styles */
.sidebar {
    position: fixed;
    left: -200px;
    top: 0;
    bottom: 0;
    width: 200px;
    background-color: #333;
    transition: left 0.3s ease;
    z-index: 999;
    overflow-y: auto;
}

.sidebar.open {
    left: 0;
}

.sidebar nav {
    margin-top: 60px;
}

.sidebar ul {
    list-style-type: none;
    padding: 0;
}

.sidebar ul li {
    padding: 10px;
    color: #fff;
    cursor: pointer;
}

.sidebar ul li:hover {
    background-color: #444;
}

/* Profile and Children Profile Styles */
.children-profile,
.profile-info,
.child-info {
    margin: 20px;
    padding: 10px;
    border-radius: 8px;
    background-color: #f9f9f9; /* Light grey background */
}

.children-profile {
    padding: 20px;
    background-color: #f9f9f9; /* Light grey background */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.profile-info {
    background-color: #fff; /* White background */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.child-info {
    background-color: #c0c0c0; /* Medium grey background */
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.child-info h3 {
    color: #000; /* Black color for headings */
    font-size: 20px;
    margin-bottom: 10px;
}

.child-info ul {
    list-style-type: disc;
    padding-left: 20px;
}

.tutors,
.clients {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9; /* Light grey background */
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tutors h2,
.clients h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333; /* Dark grey color for headings */
}

.tutors ul,
.clients ul {
    list-style-type: none;
    padding: 0;
}

.tutors li,
.clients li {
    margin-bottom: 20px;
    border: 1px solid #333; /* Dark grey border */
    border-radius: 5px;
    padding: 10px;
    background-color: #fff; /* White background for list items */
}

.tutors .tutor-name,
.clients .client-name {
    cursor: pointer;
    font-weight: bold;
    color: #333; /* Dark grey color for names */
}

.tutors .tutor-details,
.clients .client-details {
    margin-top: 10px;
    display: block;
}

.tutors .tutor-details p,
.clients .client-details p {
    margin-bottom: 5px;
    color: #333; /* Dark grey text color for details */
}

.tutors .tutor-details ul,
.clients .client-details ul {
    margin-top: 5px;
}

.tutors .tutor-details ul li,
.clients .client-details ul li {
    list-style-type: disc;
    margin-left: 20px;
    color: #333; /* Dark grey text color for list items */
}

.Schedule {
    font-family: 'Arial', sans-serif;
    margin: 20px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.Schedule h2 {
    color: #333;
    font-size: 24px;
    margin-bottom: 10px;
    text-align: center;
}

.rbc-calendar {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

/* Updated Student Info Container Styles */
.student-info-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
}

.student-info-box {
    width: calc(50% - 10px);
    margin-right: 20px;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 8px;
    background-color: #e6f7ff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.student-info-box h3 {
    color: #007bff;
    font-size: 18px;
    margin-bottom: 10px;
}

.student-info-box p {
    margin-bottom: 5px;
}

.AddUsersComponent {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9; /* Light grey background */
}

.AddUsersComponent h2 {
    margin-bottom: 20px;
    color: #333; /* Dark grey color for headings */
}

.AddUsersComponent form {
    display: grid;
    gap: 15px;
}

.AddUsersComponent label {
    font-weight: bold;
    color: #333; /* Dark grey color for labels */
}

.AddUsersComponent input,
.AddUsersComponent select,
.AddUsersComponent button {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-sizing: border-box;
}

.AddUsersComponent button {
    cursor: pointer;
    background-color: #333; /* Dark grey background for buttons */
    color: #fff; /* White text color for buttons */
    transition: background-color 0.3s ease;
}

.AddUsersComponent button:hover {
    background-color: #555; /* Slightly darker grey on hover */
}

.AddUsersComponent .error {
    color: red;
    margin-top: 10px;
}

.AddUsersComponent > div > div {
    display: grid;
    gap: 10px;
}

.AddUsersComponent > div > div > div {
    display: grid;
    gap: 10px;
    align-items: center;
}

.AddUsersComponent > div > div > div input,
.AddUsersComponent > div > div > div select,
.AddUsersComponent > div > div > div button {
    width: calc(100% - 100px);
}

.Profile {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9; /* Light grey background */
}

.Profile h2 {
    margin-bottom: 20px;
    color: #333; /* Dark grey color for headings */
}

.profile-info {
    background-color: #fff; /* White background */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 5px;
}

.profile-info p {
    margin-bottom: 10px;
    color: #333; /* Dark grey color for text */
}

.profile-info strong {
    font-weight: bold;
}

.profile-info ul {
    margin-top: 10px;
    padding-left: 20px;
}

.profile-info ul li {
    margin-bottom: 5px;
    color: #333; /* Dark grey color for list items */
}

.ClientsComponent {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9; /* Light grey background */
}

.ClientsComponent h2 {
    margin-bottom: 20px;
    color: #333; /* Dark grey color for headings */
}

.ClientsComponent ul {
    list-style: none;
    padding: 0;
}

.ClientsComponent li {
    margin-bottom: 15px;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff; /* White background for list items */
}

.ClientsComponent .client-name {
    font-weight: bold;
    cursor: pointer;
    color: #333; /* Dark grey color for client names */
}

.ClientsComponent .client-details {
    margin-top: 10px;
}

.ClientsComponent p {
    margin: 5px 0;
    color: #555; /* Slightly lighter grey for text */
}

.ClientsComponent .child-info h3 {
    margin-top: 10px;
    color: #333; /* Dark grey color for child info headings */
}

.ClientsComponent .edit-form {
    display: grid;
    gap: 15px;
    margin-top: 15px;
    border-top: 1px solid #ccc;
    padding-top: 15px;
}

.ClientsComponent input,
.ClientsComponent select,
.ClientsComponent button {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-sizing: border-box;
}

.ClientsComponent button {
    cursor: pointer;
    background-color: #333; /* Dark grey background for buttons */
    color: #fff; /* White text color for buttons */
    transition: background-color 0.3s ease;
}

.ClientsComponent button:hover {
    background-color: #555; /* Slightly darker grey on hover */
}

.ClientsComponent .error {
    color: red;
    margin-top: 10px;
}

.ClientsComponent .edit-form div {
    display: grid;
    gap: 10px;
}

.ClientsComponent .edit-form div input,
.ClientsComponent .edit-form div select,
.ClientsComponent .edit-form div button {
    width: calc(100% - 100px);
}

.ClientsComponent .edit-form .child-info div {
    display: grid;
    gap: 10px;
    align-items: center;
}

.ClientsComponent .edit-form .child-info div input,
.ClientsComponent .edit-form .child-info div select,
.ClientsComponent .edit-form .child-info div button {
    width: calc(100% - 100px);
}

.add-event-form {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9; /* Light grey background */
}

.add-event-form label {
    display: block;
    margin-bottom: 10px;
    color: #333; /* Dark grey color for labels */
}

.add-event-form input,
.add-event-form select {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    margin-bottom: 15px;
}

.add-event-form button {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: none;
    background-color: #333; /* Dark grey background for buttons */
    color: #fff; /* White text color for buttons */
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.add-event-form button:hover {
    background-color: #555; /* Slightly darker grey on hover */
}

.edit-event-form {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9; /* Light grey background */
}

.edit-event-form label {
    display: block;
    margin-bottom: 10px;
    color: #333; /* Dark grey color for labels */
}

.edit-event-form input,
.edit-event-form select {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    margin-bottom: 15px;
}

.edit-event-form button {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: none;
    background-color: #333; /* Dark grey background for buttons */
    color: #fff; /* White text color for buttons */
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.edit-event-form button:hover {
    background-color: #555; /* Slightly darker grey on hover */
}

.edit-event-form .student-info-box {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 15px;
    background-color: #e9e9e9; /* Slightly darker grey for student info boxes */
}
