body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f9fafb;
}

.Logo {
  display: block;
  margin: 20px auto;
  max-width: 300px;
}

.login-page {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.login-page p {
  margin-bottom: 15px;
  font-size: 16px;
  color: #333333;
}

.login-page form {
  display: flex;
  flex-direction: column;
}

.login-page label {
  margin-bottom: 8px;
  font-size: 14px;
  color: #444444;
}

.login-page input[type="email"],
.login-page input[type="password"] {
  padding: 12px;
  margin-bottom: 20px;
  border: 1px solid #cccccc;
  border-radius: 6px;
  font-size: 16px;
}

.login-page button[type="submit"] {
  padding: 12px;
  background-color: #060447;
  color: #ffffff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
}

.login-page button[type="submit"]:hover {
  background-color: #03032e;
}

.error-message {
  color: #ff4444;
  margin-top: 10px;
  font-size: 14px;
}

.center {
  text-align: center;
}